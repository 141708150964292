import { css } from '@emotion/react';
import { FC, ReactNode } from 'react';

import { colors } from '../../theme/colors';

import flow1 from '../../images/registration-flow/flow-1.svg';
import flow2 from '../../images/registration-flow/flow-2.svg';
import flow3 from '../../images/registration-flow/flow-3.svg';
import flow4 from '../../images/registration-flow/flow-4.svg';
import flow5 from '../../images/registration-flow/flow-5.svg';

type Props = {};

type Step = {
  title: string;
  shownTitle: ReactNode;
  description: string;
  img: string;
};

const steps: Step[] = [
  {
    title: 'paild申込み',
    shownTitle: (
      <>
        paild
        <br />
        申込み
      </>
    ),
    description: '申込みフォームより必要な情報をご入力ください。',
    img: flow1,
  },
  {
    title: 'ウォレット開設',
    shownTitle: (
      <>
        ウォレット
        <br />
        開設
      </>
    ),
    description: '申込みフォーム送信後に開設ご案内メールが届きます。',
    img: flow2,
  },
  {
    title: '本人確認',
    shownTitle: '本人確認',
    description:
      '法人の本人確認を行うため、オンライン上で情報を入力して申請してください。登記簿原本など書類の提出は不要です。',
    img: flow3,
  },
  {
    title: '初期費用のお支払い',
    shownTitle: (
      <>
        初期費用の
        <br />
        お支払い
      </>
    ),
    description:
      '初期費用の入金完了後、本人確認コードが記載されたハガキが郵送されます。コード入力をもって本人確認が完了します。',
    img: flow4,
  },
  {
    title: 'チャージ＆カード発行',
    shownTitle: (
      <>
        チャージ＆
        <br />
        カード発行
      </>
    ),
    description:
      '管理画面からウォレットへのチャージやカード発行ができます。またカードごとに利用制限をかけることも可能です。',
    img: flow5,
  },
];

export const RegistrationFlow: FC<Props> = () => {
  return (
    <div css={RegistrationFlowStyle}>
      <h2 css={RegistrationFlowHeadingStyle}>今後の流れ</h2>
      <ul css={FlowWrapperStyle}>
        {steps.map((step, index) => (
          <StepListItem key={step.title} step={step} index={index} />
        ))}
      </ul>
    </div>
  );
};

type StepProps = {
  step: Step;
  index: number;
};

const StepListItem: FC<StepProps> = (props) => {
  const listCss =
    props.index === 0
      ? [FlowStyle, BottomLine]
      : props.index === steps.length - 1
      ? [FlowStyle, TopLine]
      : [FlowStyle, BottomLine, TopLine];
  return (
    <li css={listCss}>
      <div css={StepStyle}>
        <p css={{ fontSize: 11 }}>step</p>
        <p css={{ fontSize: 22 }}>{props.index + 1}</p>
      </div>
      <img src={props.step.img} alt={props.step.title} css={[PC, ImageStyle]} />
      <p css={[PC, FlowTitleStyle]}>{props.step.shownTitle}</p>
      <p css={PC}>{props.step.description}</p>
      <div css={[SP, FlowWrapperSpStyle]}>
        <div css={FlowTitleWrapperStyle}>
          <img src={props.step.img} alt='paild申込み' css={ImageStyle} />
          <p css={FlowTitleStyle}>{props.step.shownTitle}</p>
        </div>
        <p>{props.step.description}</p>
      </div>
    </li>
  );
};

const RegistrationFlowStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 30,

  '@media(max-width: 768px)': {
    display: 'none',
  },
});

const RegistrationFlowHeadingStyle = css({
  color: colors.primary,
  fontSize: 28,
  lineHeight: 1,

  '@media(max-width: 1119px)': {
    fontSize: 20,
  },
});

const FlowWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  background: '#F9F9F9',
  borderRadius: 16,
  padding: '30px 20px 40px',
  listStyle: 'none',
});

const FlowStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: 30,
});

const StepStyle = css({
  minWidth: 80,
  height: 80,
  borderRadius: '50%',
  border: `2px solid ${colors.black}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  p: { fontWeight: 500 },
});

const ImageStyle = css({
  width: '100%',
  height: 100,
  maxWidth: 120,
  maxHeight: 100,
});

const FlowTitleStyle = css({
  width: '100%',
  maxWidth: 100,
  fontSize: 20,
  fontWeight: 700,
  textAlign: 'center',
  color: colors.primary,
});

const FlowWrapperSpStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
});

const FlowTitleWrapperStyle = css({
  display: 'flex',
  gap: 20,
  alignItems: 'center',
});

const PC = css({
  '@media(max-width: 1119px)': {
    display: 'none',
  },
});

const SP = css({
  '@media(min-width: 1120px)': {
    display: 'none',
  },
});

const BottomLine = css({
  position: 'relative',

  '&:after': {
    content: '""',
    position: 'absolute',
    background: colors.black,
    width: 2,
    height: 'calc((100% - 80px) / 2 + 20px)',
    left: 39,
    top: 'calc((100% - 80px) / 2 + 80px)',
  },
});

const TopLine = css({
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    background: colors.black,
    width: 2,
    height: 'calc((100% - 80px) / 2 + 20px)',
    left: 39,
    bottom: 'calc((100% - 80px) / 2 + 80px)',
  },
});
