import { css } from '@emotion/react';
import { FC, useState, useEffect, ChangeEvent, FocusEvent } from 'react';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useLocation } from '@reach/router';
import { HeadInner } from '../components/Head';
import {
  PostConversionInfo,
  AdditionalOption,
  handlePostWithTrackingCV,
} from '../util/post';
import { Layout } from '../components/layout';
import { RegistrationLayout } from '../components/forms/RegistrationLayout';
import { RegistrationFlow } from '../components/organisms/RegistrationFlow';
import { colors } from '../theme/colors';

export type AdditionalOptionType = {
  property: string | null;
  dropdownLabel: string | null;
  value: string;
  handleAdditionalOptionSelect: (event: ChangeEvent<HTMLSelectElement>) => void;
  dropdownPlaceholder: string | null;
  dropdown:
    | {
        name: string;
        value: string;
      }[]
    | undefined;
};

type HeadProps = {
  data: GatsbyTypes.RegistrationTemplateQuery;
};

export const Head: FC<HeadProps> = (props) => {
  const registration = props.data.contentfulRegistration;
  if (!registration) return null;

  return (
    <HeadInner
      path={`/registration/${registration.slug}/`}
      title='申込み'
      noindex={registration.noindex}
    />
  );
};

const RegistrationTemplate: FC<{
  data: GatsbyTypes.RegistrationTemplateQuery;
}> = (props) => {
  const registration = props.data.contentfulRegistration;
  if (!registration) return null;

  const [agree, setAgree] = useState(false);

  const handleAgree = () => {
    setAgree(!agree);
  };

  const location = useLocation();
  const env = process.env.CONTEXT;

  const portalId = process.env.HUBSPOT_ID ? process.env.HUBSPOT_ID : '';
  const formId =
    env === 'dev' || env === 'stg'
      ? 'cfab616d-f808-4abf-9b7e-50257c522780'
      : '98bbd78e-0919-4ab9-8823-8a207a1d81f5';

  const [form, setForm] = useState<PostConversionInfo>({
    email: '',
    lastname: '',
    firstname: '',
    company: '',
    employees: '',
    department: '',
    jobtitle: '',
    phone: '',
    industry: '',
    employee_expense_use: false,
    cashless_petty_cash: false,
    saas_expense_management: false,
    pageUri: location.href,
    registration: true,
    request_document: false,
    service_codes: ['prepaid_card'],
  });

  // contentfulで入力する固定の名前じゃないプロパティがあるので別で保持
  const [additionalOption, setAdditionalOption] = useState<AdditionalOption>({
    name: registration.property ? registration.property : '',
    value: '',
  });

  const handleAdditionalOptionSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setAdditionalOption((prevState) => ({
      ...prevState,
      value: e.target.value,
    }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({
      ...form,
      phone: e.currentTarget.value.replace(/-/g, ''),
    });
  };

  const onPostButtonClick = async () => {
    await handlePostWithTrackingCV(
      form,
      portalId,
      formId,
      document.title,
      registration.slug ? registration.slug : '-',
      additionalOption
    );
    navigate('/registration/complete/');
  };

  const additionalOptionProps = {
    property: registration.property,
    dropdownLabel: registration.dropdownLabel,
    value: additionalOption.value,
    handleAdditionalOptionSelect: handleAdditionalOptionSelect,
    dropdownPlaceholder: registration.dropdownPlaceholder,
    dropdown: registration.dropdown?.map((el) => {
      if (!el) return { name: '', value: '' };
      return { name: `${el.name}`, value: `${el.name}` };
    }),
  };

  const isShownAdditionalOption = Boolean(
    registration.isShownDropdown &&
      registration.dropdown &&
      registration.dropdownLabel &&
      registration.dropdownPlaceholder &&
      registration.property
  );

  const requiredList = [
    'company',
    'email',
    'lastname',
    'firstname',
    'employees',
    'department',
    'phone',
    'industry',
  ];

  useEffect(() => {
    if (
      registration.isShownDropdown &&
      registration.dropdown &&
      registration.dropdownLabel &&
      registration.dropdownPlaceholder &&
      registration.property
    ) {
      setForm((prevState) => ({
        ...prevState,
        [registration.property!]: registration.property
          ? registration.property
          : '',
      }));
    }
  }, []);

  const links = [
    {
      name: `${registration.title}`,
      path: `/registration/${registration.slug}/`,
    },
  ];

  return (
    <Layout
      header='simple'
      background='simple'
      footer='simple'
      isBottomMenu={false}
      links={links}
    >
      <RegistrationLayout
        form={form}
        onChange={handleChange}
        onCheckBoxChange={handleCheck}
        onSelectChange={handleSelectChange}
        onBlur={handlePhoneBlur}
        agree={agree}
        handleAgree={handleAgree}
        requiredList={requiredList}
        onPostButtonClick={onPostButtonClick}
        isShownAdditionalOption={isShownAdditionalOption}
        additionalOption={additionalOptionProps}
      >
        <h2 css={RegistrationTitleStyle}>{registration.title || ''}</h2>
        {registration.image && (
          <GatsbyImage
            image={registration.image.gatsbyImageData!}
            alt={registration.title || ''}
            objectFit='contain'
          />
        )}
        <div css={RegistrationDescriptionStyle}>
          <MDXRenderer>
            {registration.description?.childMdx?.body || ''}
          </MDXRenderer>
        </div>
        <RegistrationFlow />
      </RegistrationLayout>
    </Layout>
  );
};

const RegistrationTitleStyle = css({
  color: colors.black,
  fontSize: 40,

  '@media(max-width: 1119px)': {
    fontSize: 28,
  },
});

const RegistrationDescriptionStyle = css({
  p: { fontSize: 16, lineHeight: 2, letterSpacing: 0.05 },

  '@media(max-width: 768px)': {
    br: { display: 'none' },
    p: { fontSize: 14 },
  },
});

export const query = graphql`
  query RegistrationTemplate($slug: String) {
    contentfulRegistration(slug: { eq: $slug }) {
      slug
      title
      isShownDocumentRequest
      description {
        childMdx {
          body
        }
        description
      }
      image {
        gatsbyImageData
        file {
          url
        }
        title
      }
      isShownDropdown
      dropdownLabel
      dropdownPlaceholder
      dropdown {
        name
      }
      property
      noindex
    }
  }
`;

export default RegistrationTemplate;
